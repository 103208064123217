import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { loadProvince } from "../../../store/province/provinceActions";
import ActionMenu from "../../actionmenu/Actionmenu";
import ProvinceListTable from "./ProvinceList";
import { addItem } from "../../../store/breadcrumb/breadcrumbActions";

export default function CountryIndex() {
  const provinceList = useSelector((state) => state.province.provinceList);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const country = localStorage.getItem("country");
    dispatch(loadProvince(country));
  }, [dispatch]);

  const onRowSelected = (province) => {
    localStorage.setItem("provinceId", province.id);
    dispatch(addItem({ title: province.name, url: "/mapper/city" })); //Dispatch action to add Selected province to breadcrumb
    history.push("/mapper/city");
  };

  return (
    <div>
      <ActionMenu />
      <ProvinceListTable
        tabaledata={provinceList}
        rowSelected={onRowSelected}
      />
    </div>
  );
}
