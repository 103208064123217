import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { loadCity } from "../../../store/city/cityActions";
import ActionMenu from "../../actionmenu/Actionmenu";
import CityListTable from "./CityList";
import { addItem } from "../../../store/breadcrumb/breadcrumbActions";

export default function CityIndex() {
  const cityList = useSelector((state) => state.city.cityList);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const country = localStorage.getItem("country");
    const provinceId = localStorage.getItem("provinceId");
    dispatch(loadCity(country, provinceId));
  }, [dispatch]);

  const onRowSelected = (city) => {
    localStorage.setItem("cityId", city.id);
    dispatch(addItem({ title: city.name_en, url: "/mapper/area" })); //Dispatch action to add Selected city to breadcrumb
    history.push("/mapper/area");
  };

  return (
    <div>
      <ActionMenu />
      <CityListTable tabaledata={cityList} rowSelected={onRowSelected} />
    </div>
  );
}
