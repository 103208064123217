import Axios from "axios";
import * as changeLogActionTypes from "./changeLogActionTypes";

export const setChangeLogList = (logList) => {
  return {
    type: changeLogActionTypes.LOAD_CHANGELOG,
    value: logList,
  };
};

export const loadChageLogs = () => {
  return (dispatch) => {
    Axios.get(`${process.env.REACT_APP_API_URL}/api/address/changelog`)
      .then((res) => {
        dispatch(setChangeLogList(res.data.response.changes));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const clearChangeLogs = () => {
  return {
    type: changeLogActionTypes.CLEAR_CHANGELOG,
    value: [],
  };
};
