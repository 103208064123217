import * as changeLogActionTypes from "./changeLogActionTypes";

const initialState = {
  changeLogs: [],
  hasChanges: false,
};

const loadChangesLog = (state, action) => {
  return {
    ...state,
    changeLogs: action.value,
    hasChanges: action.value.length > 0 ? true : false,
  };
};
const changesLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case changeLogActionTypes.LOAD_CHANGELOG:
      return loadChangesLog(state, action);
    case changeLogActionTypes.CLEAR_CHANGELOG:
      return { changeLogs: [], hasChanges: false };
    default:
      return state;
  }
};
export default changesLogReducer;
