import "./App.css";
import Login from "./components/login/Login";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import CountryIndex from "./components/content/country/CountryIndex";
import ProvinceIndex from "./components/content/province/ProvinceIndex";
import AddressMapperToolbar from "./components/toolbar/Toolbar";
import CityIndex from "./components/content/city/CityIndex";
import AreaIndex from "./components/content/area/AreaIndex";
import BreadcrumbIndex from "./components/breadcrumb/BreadcrumbIndex";
import ActionNotification from "./components/ui/ActionNotification";
import Spinner from "./components/ui/Spinner";
function App() {
  return (
    <div className="App">
      <AddressMapperToolbar />
      <BrowserRouter>
        <BreadcrumbIndex />
        <Switch>
          <Route path="/mapper/country" exact component={CountryIndex} />
          <Route path="/mapper/province" exact component={ProvinceIndex} />
          <Route path="/mapper/city" exact component={CityIndex} />
          <Route path="/mapper/area" exact component={AreaIndex} />
          <Route path="/" exact component={Login} />
        </Switch>
      </BrowserRouter>
      <ActionNotification />
      <Spinner />
    </div>
  );
}

export default App;
