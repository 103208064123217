import * as notificationActionTypes from "./notificationActionTypes";

const initialState = {
  message: "",
  severity: "success",
  //notificationObj: [],
  shownotification: false,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case notificationActionTypes.PUSH_MESSAGE:
      return {
        message: action.value.message,
        severity: action.value.severity,
        //notificationObj: state.notificationObj.concat(action.value),
        shownotification: true,
      };
    case notificationActionTypes.HIDE_NOTIFICATION:
      return {
        message: "",
        severity: "success",
        //notificationObj: state.notificationObj.filter((n,idx) => idx !== action.value),
        shownotification: false,
      };
    default:
      return state;
  }
};

export default notificationReducer;
