import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteModal from "../../ui/DeleteModal";
import PublishIcon from "@material-ui/icons/Publish";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { publishCountry } from "../../../store/country/countryActions";
import logo from "../../../assets/header-color.svg";
import ChangeLogs from "./ChangeLogs";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.grey[400],
    color: theme.palette.common.white,
    fontSize: 16,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  root: {
    maxWidth: 345,
  },
  media: {
    height: 110,
  },
});

export default function CountryListTable(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    publishAction: false,
    selectedItem: null,
    openChangeLogs: false,
  });

  const publishAction = (event) => {
    event.stopPropagation();
    setState({
      ...state,
      publishAction: true,
    });
  };

  const openChangeLogs = () => {
    console.log("changelog");
    setState({
      ...state,
      openChangeLogs: true,
    });
  };

  const closeModal = (name) => {
    setState({ ...state, [name]: false });
  };

  const publishActionConfirmation = () => {
    dispatch(publishCountry());
    closeModal("publishAction");
  };
  const modalPopUp = () => {
    if (state.publishAction) {
      return (
        <DeleteModal
          open={state.publishAction}
          close={() => closeModal("publishAction")}
          publishConfirmation={publishActionConfirmation}
        />
      );
    } else if (state.openChangeLogs) {
      console.log("change handled " + state.openChangeLogs);
      return (
        <ChangeLogs
          open={state.openChangeLogs}
          close={() => closeModal("openChangeLogs")}
        />
      );
    }
  };

  const tabledoby = () => {
    if (props.tabaledata) {
      return props.tabaledata.map((data) => (
        <StyledTableRow
          key={data.id}
          hover
          root
          onClick={() => props.rowSelected(data)}
        >
          {props.tableheader.map((cell, index) => (
            <TableCell align="left" key={index}>
              {data[cell.key]}
            </TableCell>
          ))}
        </StyledTableRow>
      ));
    } else {
      <div>No data found</div>;
    }
  };
  return (
    <Container maxWidth="xl">
      <Typography variant="subtitle2" gutterBottom align="left">
        Country List
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TableContainer component={Paper}>
            <Table
              stickyHeader
              className={classes.table}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  {props.tableheader.map((row) => (
                    <StyledTableCell align="left" key={row.name}>
                      {row.name}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>{tabledoby()}</TableBody>
            </Table>
          </TableContainer>
          {modalPopUp()}
        </Grid>
        <Grid item xs={4}>
          <Card className={classes.root}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={logo}
                title="Changes Log"
              />
              <CardContent>
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  align="left"
                >
                  {props.hasChanges
                    ? "You have few changes pending to be published."
                    : "You don't have any changes yet."}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                size="small"
                color="primary"
                disabled={!props.hasChanges}
                onClick={openChangeLogs}
              >
                View Change Details
              </Button>
              <Button
                disabled={!props.hasChanges}
                variant="contained"
                color="secondary"
                onClick={publishAction}
                endIcon={<PublishIcon />}
              >
                Publish
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
