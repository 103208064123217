import { publishMessage } from "../store/notification/notificationActions";

export const handleHttpError = (dispatch, err) => {
  if (err.response) {
    dispatch(
      publishMessage({
        message: err.response.data.statusMsg,
        severity: "error",
      })
    );
  } else if (err.request) {
    dispatch(
      publishMessage({
        message: err.message,
        severity: "error",
      })
    );
  } else {
    dispatch(
      publishMessage({
        message: "Something went wrong.",
        severity: "error",
      })
    );
  }
};
