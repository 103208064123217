import React from "react";
import CountryListTable from "./CountryList";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addItem } from "../../../store/breadcrumb/breadcrumbActions";

export default function Country(props) {
  const tableheader = [
    { name: "Title", key: "title", type: "text" },
    //{ name: "Enabled", key: "enabled", type: "switch" },
    //{ name: "COD", key: "cod", type: "text" },
  ];

  const cretetableheader = [
    { name: "Title", key: "title", type: "text" },
    //{ name: "Name En", key: "name_en", type: "text" },
    //{ name: "Name Ar", key: "name_ar", type: "text" },
    //{ name: "COD", key: "cod", type: "text" },
    //{ name: "Enabled", key: "enabled", type: "switch" },
  ];

  /*   const [state, setState] = useState({
    title: "New Country",
    name_en: "",
    name_ar: "",
    enabled: true,
    cod: "",
    countryList: [],
  });
 */
  /*   const createCountry = (e) => {
    e.preventDefault();
    console.log(state);
  };

  const changeFieldValue = (event) => {
    if (event.target.type === "checkbox") {
      setState({ ...state, [event.target.name]: event.target.checked });
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  }; */
  /*   const addNewCountry = () => {
    if (props.openAddCountryModal) {
      return (
        <ManageItem
          open={props.openAddCountryModal}
          close={props.closeAddCountryModal}
          metadata={cretetableheader}
          data={state}
          actionHandler={createCountry}
          changeFieldValue={changeFieldValue}
        />
      );
    }
  }; */

  const history = useHistory();
  const dispatch = useDispatch();

  const onSelectedCountry = (selectedCountry) => {
    localStorage.setItem("country", selectedCountry.title);
    dispatch(
      addItem({ title: selectedCountry.title, url: "/mapper/province" })
    ); //Dispatch action to add Selected country to breadcrumb
    history.push("/mapper/province");
  };

  return (
    <div>
      <CountryListTable
        tableheader={tableheader}
        tabaledata={props.countryList}
        rowSelected={onSelectedCountry}
        cretetableheaderMeta={cretetableheader}
        changeLogs={props.changeLogs}
        hasChanges={props.hasChanges}
      />

      {/* {addNewCountry()} */}
    </div>
  );
}
