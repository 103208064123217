import { makeStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    margin: "auto",
    maxWidth: "auto",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

export default function Breadcrumb(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={3}>
        <Breadcrumbs aria-label="breadcrumb">
          {props.breadcrumb.map((row, idx) => (
            <Link
              color="inherit"
              key={row.title}
              href="/"
              onClick={(e) => props.navigateBreadcrumb(e, row)}
            >
              {idx === 0 ? (
                <HomeIcon className={classes.icon} color="primary" />
              ) : (
                ""
              )}
              {row.title}
            </Link>
          ))}
        </Breadcrumbs>
      </Paper>
    </div>
  );
}
