import Axios from "axios";
import { publishMessage } from "../notification/notificationActions";
import * as provinceActionTypes from "./provinceActionTypes";
import * as message from "../../message";
import { hideSpinner, showSpinner } from "../spinner/spinnerActions";
import { handleHttpError } from "../../util/httpErrorHandler";

export const setProvinceList = (provinces) => {
  return {
    type: provinceActionTypes.LOAD_PROVINCE,
    value: provinces,
  };
};

export const loadProvince = (country) => {
  const payload = { country: country };
  return (dispatch) => {
    dispatch(showSpinner());
    Axios.post(
      `${process.env.REACT_APP_API_URL}/api/address/find/province`,
      payload
    )
      .then((res) => {
        dispatch(setProvinceList(res.data.response));
        dispatch(
          publishMessage({
            message: message.LOAD_PROVINCE_SUCCESS,
            severity: "success",
          })
        );
        dispatch(hideSpinner());
      })
      .catch((err) => {
        handleHttpError(dispatch, err);
        dispatch(hideSpinner());
      });
  };
};

export const updateProvince = (province) => {
  const country = localStorage.getItem("country");
  const payload = { objkey: country, country, province };
  return (dispatch) => {
    dispatch(showSpinner());
    Axios.put(`${process.env.REACT_APP_API_URL}/api/address/province`, payload)
      .then((res) => {
        dispatch(loadProvince(country));
        dispatch(
          publishMessage({
            message: message.UPDATE_PROVINCE_SUCCESS,
            severity: "success",
          })
        );
        dispatch(hideSpinner());
      })
      .catch((err) => {
        handleHttpError(dispatch, err);
        dispatch(hideSpinner());
      });
  };
};

export const createNewProvince = (province) => {
  const country = localStorage.getItem("country");
  const payload = { country: country, province };
  return (dispatch) => {
    dispatch(showSpinner());
    Axios.post(`${process.env.REACT_APP_API_URL}/api/address/province`, payload)
      .then((res) => {
        dispatch(loadProvince(country));
        dispatch(
          publishMessage({
            message: message.CREATE_PROVINCE_SUCCESS,
            severity: "success",
          })
        );
        dispatch(hideSpinner());
      })
      .catch((err) => {
        console.log(err.request);
        handleHttpError(dispatch, err);
        dispatch(hideSpinner());
      });
  };
};
