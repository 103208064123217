import React from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { hideNotification } from "../../store/notification/notificationActions";

export default function ActionNotification() {
  const dispatch = useDispatch();
  const [state] = React.useState({
    vertical: "top",
    horizontal: "right",
    alertHideDuration: 4000,
  });

  const { vertical, horizontal } = state;
  const shownotification = useSelector(
    (state) => state.notification.shownotification
  );
  const severity = useSelector((state) => state.notification.severity);
  const message = useSelector((state) => state.notification.message);

  const hideNotificationMessage = () => {
    dispatch(hideNotification());
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={shownotification}
      key={state.vertical + state.horizontal}
      autoHideDuration={state.alertHideDuration}
      onClose={hideNotificationMessage}
    >
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  );
}
