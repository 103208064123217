import Axios from "axios";
import { publishMessage } from "../notification/notificationActions";
import * as cityActionTypes from "./cityActionTypes";
import * as message from "../../message";
import { hideSpinner, showSpinner } from "../spinner/spinnerActions";
import { handleHttpError } from "../../util/httpErrorHandler";

export const setCityList = (cities) => {
  return {
    type: cityActionTypes.LOAD_CITY,
    value: cities,
  };
};

export const clearCity = () => {
  return {
    type: cityActionTypes.CLEAR_CITY,
  };
};

export const loadCity = (country, provinceId) => {
  const payload = { country: country, provinceId };
  return (dispatch) => {
    dispatch(showSpinner());
    Axios.post(
      `${process.env.REACT_APP_API_URL}/api/address/find/city`,
      payload
    )
      .then((res) => {
        dispatch(setCityList(res.data.response));
        dispatch(
          publishMessage({
            message: message.LOAD_CITY,
            severity: "success",
          })
        );
        dispatch(hideSpinner());
      })
      .catch((err) => {
        dispatch(clearCity());
        handleHttpError(dispatch, err);
        dispatch(hideSpinner());
        console.log(err);
      });
  };
};

export const updateCity = (city) => {
  const country = localStorage.getItem("country");
  const provinceId = localStorage.getItem("provinceId");
  const payload = { objkey: country, country, provinceId, city };
  return (dispatch) => {
    dispatch(showSpinner());
    Axios.put(`${process.env.REACT_APP_API_URL}/api/address/city`, payload)
      .then((res) => {
        dispatch(loadCity(country, provinceId));
        dispatch(
          publishMessage({
            message: message.UPDATE_CITY_SUCCESS,
            severity: "success",
          })
        );
        dispatch(hideSpinner());
      })
      .catch((err) => {
        handleHttpError(dispatch, err);
        dispatch(hideSpinner());
        console.log(err);
      });
  };
};

export const addNewCity = (city) => {
  const country = localStorage.getItem("country");
  const provinceId = localStorage.getItem("provinceId");
  const payload = { objkey: country, country, provinceId, city };
  return (dispatch) => {
    dispatch(showSpinner());
    Axios.post(`${process.env.REACT_APP_API_URL}/api/address/city`, payload)
      .then((res) => {
        dispatch(loadCity(country, provinceId));
        dispatch(
          publishMessage({
            message: message.UPDATE_CITY_SUCCESS,
            severity: "success",
          })
        );
        dispatch(hideSpinner());
      })
      .catch((err) => {
        handleHttpError(dispatch, err);
        dispatch(hideSpinner());
        console.log(err);
      });
  };
};

export const setDefaultCity = (city) => {
  console.log(city);
  const country = localStorage.getItem("country");
  const payload = { country, city };
  return (dispatch) => {
    dispatch(showSpinner());
    Axios.post(
      `${process.env.REACT_APP_API_URL}/api/address/default/city`,
      payload
    )
      .then((res) => {
        dispatch(
          publishMessage({
            message: message.DEFAULT_CITY,
            severity: "success",
          })
        );
        dispatch(hideSpinner());
      })
      .catch((err) => {
        handleHttpError(dispatch, err);
        dispatch(hideSpinner());
      });
  };
};
