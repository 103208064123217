import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import DeleteModal from "../../ui/DeleteModal";
import {
  updateCity,
  addNewCity,
  setDefaultCity,
} from "../../../store/city/cityActions";
import { useDispatch } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import ManageCity from "./ManageCity";
import { Container, Fab, Tooltip, Typography } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.grey[400],
    color: theme.palette.common.white,
    fontSize: 16,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  createButton: {
    position: "absolute",
    bottom: theme.spacing(12),
    right: theme.spacing(4),
  },
  tableContainer: {
    maxHeight: 600,
  },
}));

const tableheader = [
  { name: "Name EN", key: "name_en", type: "text" },
  { name: "Name AR", key: "name_ar", type: "text" },
  { name: "Enabled", key: "enabled", type: "switch" },
  { name: "Min SLA", key: "min_sla", type: "text" },
  { name: "Max SLA", key: "max_sla", type: "text" },
  { name: "COD", key: "cod_verification", type: "switch" },
  { name: "Threshold Amount", key: "threshold", type: "text" },
];

export default function CityListTable(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [state, setState] = React.useState({
    deleteAction: false,
    editAction: false,
    createAction: false,
    selectedItem: null,
    createItem: {
      id: 0,
      name_en: "",
      name_ar: "",
      enabled: true,
      min_sla: 0,
      max_sla: 0,
      cod_verification: false,
      threshold: 0,
    },
  });

  const editRow = (e, data) => {
    e.stopPropagation();
    setState({
      ...state,
      editAction: true,
      deleteAction: false,
      createAction: false,
      selectedItem: data,
    });
  };
  const defaultCity = (e, data) => {
    e.stopPropagation();
    dispatch(setDefaultCity(data));
  };

  const closeModal = (name) => {
    setState({ ...state, [name]: false });
  };

  const updateCityHandler = (city) => {
    dispatch(updateCity(city));
    closeModal("editAction");
  };

  const createCityAction = (city) => {
    dispatch(addNewCity(city));
    closeModal("createAction");
  };

  const openCreateModal = () => {
    setState({ ...state, createAction: true });
  };

  const modalPopUp = () => {
    if (state.deleteAction) {
      return (
        <DeleteModal
          open={state.deleteAction}
          close={() => closeModal("deleteAction")}
          title={state.selectedItem.title}
          deleteConfirmation={props.deleteAPICall}
        />
      );
    } else if (state.editAction) {
      return (
        <ManageCity
          headerMessage="Update City"
          open={state.editAction}
          close={() => closeModal("editAction")}
          data={state.selectedItem}
          actionHandler={updateCityHandler}
        />
      );
    } else if (state.createAction) {
      return (
        <ManageCity
          headerMessage="Add New City"
          open={state.createAction}
          close={() => closeModal("createAction")}
          data={state.createItem}
          actionHandler={createCityAction}
        />
      );
    }
  };

  const tabledoby = () => {
    if (props.tabaledata) {
      return props.tabaledata.map((data) => (
        <StyledTableRow
          key={data.id}
          hover
          root
          onClick={() => props.rowSelected(data)}
        >
          {tableheader.map((cell, index) => (
            <TableCell align="left" key={index}>
              {cell.type === "switch" ? data[cell.key] + "" : data[cell.key]}
            </TableCell>
          ))}
          <TableCell>
            <Tooltip title="Edit City">
              <Fab
                color="default"
                aria-label="edit"
                size="small"
                variant="extended"
                disableRipple="true"
              >
                <EditIcon onClick={(e) => editRow(e, data)} color="primary" />
              </Fab>
            </Tooltip>
            <Tooltip title="Set As Default">
              <Fab
                color="default"
                aria-label="edit"
                size="small"
                variant="extended"
              >
                <DoneIcon
                  onClick={(e) => defaultCity(e, data)}
                  color="secondary"
                />
              </Fab>
            </Tooltip>
          </TableCell>
        </StyledTableRow>
      ));
    } else {
      <div>No data found</div>;
    }
  };
  return (
    <React.Fragment>
      <Fab
        color="primary"
        aria-label="add"
        onClick={openCreateModal}
        className={classes.createButton}
      >
        <AddIcon />
      </Fab>
      <Container maxWidth="xl">
        <Typography variant="subtitle2" gutterBottom align="left">
          City List
        </Typography>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table
            stickyHeader
            className={classes.table}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {tableheader.map((row) => (
                  <StyledTableCell align="left" key={row.name}>
                    {row.name}
                  </StyledTableCell>
                ))}
                <StyledTableCell align="left">Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>{tabledoby()}</TableBody>
          </Table>
        </TableContainer>
      </Container>
      {modalPopUp()}
    </React.Fragment>
  );
}
