import * as countryActionTypes from "./countryActionTypes";

const initialState = {
  countryList: [],
  error: null,
};

const loadCountry = (state, action) => {
  return {
    ...state,
    countryList: action.value,
  };
};

const addCountry = (state, action) => {
  return {
    ...state,
    countryList: state.countryList.concat(action.value),
  };
};

const failedToLoadCountry = (state, action) => {
  return {
    countryList: [],
    error: action.value,
  };
};

const countryReducer = (state = initialState, action) => {
  switch (action.type) {
    case countryActionTypes.LOAD_COUNTRY:
      return loadCountry(state, action);
    case countryActionTypes.ADD_COUNTRY:
      return addCountry(state, action);
    case countryActionTypes.LOAD_COUNTRY_FAILED:
      return failedToLoadCountry(state, action);
    default:
      return state;
  }
};

export default countryReducer;
