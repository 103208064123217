import Axios from "axios";
import * as countryActionTypes from "./countryActionTypes";
import { hideSpinner, showSpinner } from "../spinner/spinnerActions";
import { handleHttpError } from "../../util/httpErrorHandler";
import { publishMessage } from "../notification/notificationActions";
import { clearChangeLogs } from "../changelog/changeLogActions";

export const setCountryList = (countryList) => {
  return {
    type: countryActionTypes.LOAD_COUNTRY,
    value: countryList,
  };
};

export const fetchCountryListFailed = (error) => {
  return {
    type: countryActionTypes.LOAD_COUNTRY_FAILED,
    value: error,
  };
};

export const loadCountry = () => {
  return (dispatch) => {
    dispatch(showSpinner());
    Axios.post(`${process.env.REACT_APP_API_URL}/api/address/find/country`)
      .then((res) => {
        dispatch(setCountryList(res.data.response));
        dispatch(hideSpinner());
      })
      .catch((err) => {
        console.log(err);
        dispatch(fetchCountryListFailed(err));
        dispatch(hideSpinner());
        handleHttpError(dispatch, err);
      });
  };
};

export const addCountry = (country) => {
  return {
    type: countryActionTypes.ADD_COUNTRY,
    value: country,
  };
};

export const publishCountry = () => {
  return (dispatch) => {
    dispatch(showSpinner());
    Axios.post(`${process.env.REACT_APP_API_URL}/api/address/publish`)
      .then((res) => {
        dispatch(hideSpinner());
        dispatch(
          publishMessage({
            message: `Changes has been published successfully.`,
            severity: "success",
          })
        );
        dispatch(clearChangeLogs());
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideSpinner());
        handleHttpError(dispatch, err);
      });
  };
};
