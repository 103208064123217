export const LOAD_PROVINCE_SUCCESS = "Provinces Loaded Successfully";
export const LOAD_CITY = "Cities Loaded Successfully";
export const LOAD_AREA_SUCCESS = "Areas Loaded Successfully";

export const UPDATE_PROVINCE_SUCCESS = "Provinces Successfully Updated";
export const UPDATE_CITY_SUCCESS = "Cities Successfully Updated";
export const UPDATE_AREA_SUCCESS = "Areas Successfully Updated";

export const CREATE_PROVINCE_SUCCESS = "Provinces Successfully Created";
export const CREATE_CITY_SUCCESS = "City Successfully Created";
export const CREATE_AREA_SUCCESS = "Area Successfully Created";
export const DEFAULT_CITY = "Default city has been set.";
