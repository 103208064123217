import * as breadcrumbActionTypes from "./breadcrumbActionTypes";

export const addItem = (item) => {
  return {
    type: breadcrumbActionTypes.ADD_ITEM,
    value: item,
  };
};

export const removeItem = (item) => {
  return {
    type: breadcrumbActionTypes.REMOVE_ITEM,
    value: item,
  };
};
