import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadArea } from "../../../store/area/areaActions";
import ActionMenu from "../../actionmenu/Actionmenu";
import AreaListTable from "./AreaList";

export default function CityIndex() {
  const areaList = useSelector((state) => state.area.areaList);
  const dispatch = useDispatch();

  useEffect(() => {
    const country = localStorage.getItem("country");
    const provinceId = localStorage.getItem("provinceId");
    const cityId = localStorage.getItem("cityId");
    dispatch(loadArea(country, provinceId, cityId));
  }, [dispatch]);

  return (
    <div>
      <ActionMenu />
      <AreaListTable tabaledata={areaList} />
    </div>
  );
}
